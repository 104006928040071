.comp-link{
    color: rgb(140, 0, 255); 
    text-decoration: underline; 
  }
  .comp-link:hover{
    color: #00c75a; 
    text-decoration: none; 
  }

  .proj-name{
    color: black;
  }