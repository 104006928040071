.project {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .project h3 {
    font-size: 1.8rem;
    color: rgb(45, 6, 88);
  }
  
  .project p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
  }
  
  .proj-img{
    width: 8vw;
    height: 12vw;
    object-fit: fill;
    display: block;
    border-radius: 10px;
  }

  .category{
    color:rgb(127, 129, 255);
    font-weight: bold;
  }

  .prev-button {
    background: none;
    border: none;
    color: red;
    text-decoration: underline; 
    cursor: pointer;
  }
  