.flex-container {
    width: 62vw; /* Container takes 62% of the viewport width */
    display: flex;
    justify-content: space-between;
    align-items: stretch; /* Ensure both elements stretch to the same height */
    gap: 20px; /* Space between the carousel and the right content */
    margin: auto;/* Center the container horizontally */
}

.carousel-container {
    
    display: flex;
    align-items: center;
    flex: 1; /* Carousel will take flexible space */
}

#photo-album-carousel {

    padding: 2vw;

    background-color: #ffffff;


    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Contain the overflow for the entire carousel */
    color: rgb(18, 67, 67);

    
}

#photo-album-carousel h2{
    
    color: rgb(62, 52, 173);
    font-family: 'Dancing Script', cursive;



    font-size: 3rem;
    margin-top: 0;


}
.carousel .control-arrow {
    background-color: rgba(0, 0, 0, 1);
    border-radius: 50%;
    height: 5vh;
    width: 5vh;
    color: white;

    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    transform: translateY(0%);
}

.carousel-image {

    
    width: 50vw;
    height: 30vw;
    object-fit: cover;
    display: block;
    border-radius: 10px;
    object-position: center; /* Center the image vertically */
}

.right-content {
    width: 28vw; /* Right content now takes the remaining space */

    padding: 20px;
    border-radius: 10px;


}

.right-content p{

    font-size: 2.4vw;
}
@media (max-width: 600px) {
    .flex-container {

        flex-direction: column; /* Stack the items vertically */
        width: 70vw; /* Reduce width to fit mobile screens */
       
        
        
    }

    .carousel-container {
        width: 50vw; /* Carousel takes full width on small screens */
        margin: auto;
    }

    .right-content {

        width: 60vw; /* Right content takes full width below carousel */

    }

    #photo-album-carousel {
        width: 100%; /* Ensure the carousel takes full width on small screens */
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .carousel-image {
        
        
        height: 40vh; /* Adjust height for smaller screens */
    }
}