


.greeting {
  margin-top: 5rem;
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  color: #0b4a21;
  text-shadow: 1px 1px 4px rgba(20, 34, 146, 0.1);
}



#bio::before,
#work::before,
#project::before,
#more::before {

  content: '';
  display: block;
  height: 2rem; /* Same height as your navbar */
  margin-top: -2rem; /* Pull the section back by the same amount */
}

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Allow body to grow if content exceeds screen height */
  overflow: auto; /* Enable scrolling when necessary */
}

.App {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

h2 {
  font-size: 1.5rem; /* Font size based on rem for better scalability */
  margin-bottom: 1rem;
}

.name {
  color: #e0e0e0;
  text-align: center;

}

.role {
  position: relative;
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 1rem;
  text-align: center;
}

.contact-info {
  margin-bottom: 2rem;
  margin-top: 1rem;
  color: #b2e3e4;
  font-size: 1vw;
}

.contact-info p {
  margin: 1rem 0;
}

.links {
  position: relative;
  margin-top: 20vh;
  left: 0;
  width: 100%;
  text-align: center;
}


.links a {
  color: #007BFF;
  text-decoration: none;
  font-size: 1rem;
  margin: 0.5rem;
}

.links a:hover {
  color: white;
  text-decoration: underline;
}




/* Sidebar Styling */
.sidebar {
  background-color: #121212;
  width: 25vw; /* Sidebar takes 25% of the viewport width */
  height: 100vh; /* Sidebar takes the full height of the viewport */
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}


.App-header {
  text-align: center;
  padding: 3rem;
}

.profile-container {
  
  position: static;
  width: min(20vw, 20vh); 
  height: min(20vw, 20vh); 
  border-radius: 50%; 
  

  margin: 0 0;
}

.profile-image {
  position: static;
  width: min(20vw, 20vh); 
  height: min(20vw, 20vh); 

  object-fit: cover;
  object-position: center 15%;
  border-radius: 50%;

}


/* Content Section */
.content {
  position: relative; /* Ensure content is positioned naturally relative to the viewport */
  left: 25vw; /* Align content next to the sidebar */
  width: 75vw; /* Take up the remaining 75% of the viewport width */
  min-height: 100vh; /* Ensure content takes up at least the full height */
  background-color: #c9cceb62;
  color: #333333;
  overflow-y: auto;
  box-sizing: border-box; 
  padding: 2rem 1rem;

}


.content section {
  margin-bottom: 0rem;
}

.content h2 {
  font-size: 2rem; /* Responsive font size */
  color: #04a5a0;
  margin-bottom: 1rem;
  font-family: 'Merriweather', serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.content p {
  font-size: 1.2rem;
  line-height: 1.6;

}

/* Navbar Styling */
.navbar {
  
  position: fixed;
  top: 0;
  left: 25vw;
  width: 75vw;
  

  z-index: 1000;
  background-color: #333;
  color: white;
  padding: 1rem 0;
}

.navbar nav ul {
  justify-content: center;
  list-style: none;
  display: flex;
  gap: 5rem; /* Use rem for consistent spacing */
  margin: 0;
  padding: 0;
}

.navbar nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.navbar nav ul li a:hover {
  text-decoration: underline;
  color: antiquewhite;
}

/* Media query for smaller screens */
@media (max-width: 700px) {
  .navbar nav ul {
    justify-content: center;
    list-style: none;
    display: flex;
    gap: 2.5rem; /* Use rem for consistent spacing */
    margin: 0;
    padding: 0;
  }


  
  .App {
    flex-direction: column; /* Stack sidebar and content vertically */
    height: auto; /* Allow the App to grow with content */
  }

  .sidebar {
    margin-top: 2rem;
    position: relative; /* Sidebar stacks with content */
    width: 100%; /* Sidebar takes full width */
    height: auto; /* Adjust height dynamically */
  }

  .contact-info {
    margin-top: 2rem;

    font-size: 3.5vw;
  }


  .content {
    left: 0; /* Reset the left margin for the content */
    width: 100%; /* Content should take full width */
    height: auto; /* Allow content to grow */
    padding: 10%; /* Increase padding for better spacing */
    overflow-y: auto; /* Enable scrolling in the content area */
  }

  .navbar {
    
   

    width: 100vw;
    left: 0;
    overflow-y: auto;

    width: 100%; /* Make the navbar take full width */
  }

  .profile-container {
    
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  display: flex;
  justify-content: center;
    
    margin-bottom: 18vh;
    margin-top: 2vh;
    

  }

  .profile-image {
    
  position: static;
  width: min(40vw, 40vh); 
  height: min(40vw, 40vh);


  


}

  .links {
    position: relative;
    bottom: 1rem; /* Adjust this value to control how far above the bottom the links should be */
    left: 0;
    margin-top: 9vh;
    width: 100%;
    text-align: center;
  }
  

  body {
    overflow-y: auto; /* Enable scrolling for the entire page */
  }
  .contact-info p {
    margin: 1.5rem 0; 
}
}
